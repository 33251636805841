:root {
  --primary-color: #14747480;
  --gray_900_14: #10182814;
  --indigo_A400_19: #534ce519;
  --white_A700_51: #ffffff51;
  --white_A700_ea: #ffffffea;
  --black_900_b7: #000000b7;
  --gray_900_19: #05004d19;
  --indigo_A200_7f: #5c54ff7f;
  --teal_A700_4c: #00c58c4c;
  --deep_orange_500: #fc6719;
  --gray_805: #3a3a40;
  --deep_orange_100: #ffb8b8;
  --red_500_33: #ff363633;
  --teal_A700: #00c58c;
  --black_900_0a: #0000000a;
  --gray_400: #c7c7cc;
  --gray_900_bf: #282829bf;
  --teal_A701: #04db9d;
  --gray_800: #3a3a3c;
  --indigo_A200_19: #5c54ff19;
  --bluegray_800: #3f3d56;
  --black_900_99: #00000099;
  --bluegray_401: #7b8898;
  --indigo_A700: #3a30ff;
  --bluegray_400: #8e8e93;
  --indigo_A200_0c: #5c54ff0c;
  --black_900_14: #00000014;
  --indigo_600: #3d36b2;
  --gray_51: #f7f6ff;
  --gray_900_33: #1c1c1e33;
  --gray_900_34: #05004d33;
  --light_blue_601: #01a3df;
  --light_blue_600: #00a3e0;
  --amber_A200: #ffdc42;
  --gray_50: #f5f4ff;
  --indigo_A200_5e: #5c54ff5e;
  --white_A700_cc: #ffffffcc;
  --white_A700_33: #ffffff33;
  --black_900_66: #00000066;
  --red_A400: #ff2d55;
  --gray_903: #282829;
  --red_500_14: #ff3a3014;
  --gray_901: #05004d;
  --gray_902: #1e1e21;
  --gray_900: #1c1c1e;
  --orange_500: #ff9500;
  --gray_101: #f3f3ff;
  --gray_100: #f1f2f7;
  --bluegray_300: #9ca8b3;
  --indigo_A400: #534ce5;
  --white_A700_7f: #ffffff7f;
  --indigo_400_14: #5856d614;
  --black_900_3d: #0000003d;
  --red_600: #e92434;
  --deep_purple_800: #362fb1;
  --teal_A700_14: #00c58c14;
  --green_A700: #00bc37;
  --white_A700_14: #ffffff14;
  --deep_orange_700: #f1361d;
  --white_A700_19: #ffffff19;
  --red_A700: #ed0006;
  --bluegray_300_bf: #9ca8b3bf;
  --white_A700_c1: #ffffffc1;
  --amber_700: #f9a000;
  --gray_600_29: #78788028;
  --teal_A700_28: #04dc9e28;
  --teal_A700_26: #00c58c26;
  --gray_600_28: #74748028;
  --indigo_400: #5856d6;
  --indigo_A200_4c: #5c54ff4c;
  --bluegray_600: #575a89;
  --gray_600_1e: #7676801e;
  --white_A700: #ffffff;
  --teal_A700_33: #00c58c33;
  --orange_500_14: #ff950014;
  --indigo_A200_26: #5c54ff26;
  --red_501: #ff3636;
  --indigo_A200_28: #5e5ce628;
  --indigo_A201: #5c54ff;
  --gray_800_bf: #3a3a40bf;
  --indigo_A200: #5e5ce6;
  --red_500: #ff3a30;
  --gray_600_14: #74748014;
  --black_900_1e: #0000001e;
  --black_900: #000000;
  --indigo_400_1e: #5856d61e;
  --black_901: #0c0c0e;
  --indigo_A200_33: #5c54ff33;
  --gray_700: #636366;
  --black_900_e0: #000000e0;
  --indigo_52: #e7e6ff;
  --orange_A700: #ff5e00;
  --indigo_50: #dddff2;
  --indigo_51: #e9ebff;
  --bluegray_100: #d1d1d6;
  --red_A400_14: #ff2d5514;
  --gray_300: #e5e5ea;
  --bluegray_900: #2f2e41;
  --indigo_100: #bdbcf5;
  --indigo_504: #4e47d9;
  --indigo_502: #4b43db;
  --indigo_503: #4e47d8;
  --indigo_500: #4841db;
  --indigo_501: #3f38d3;
}

* {
  box-sizing: border-box;
  line-height: normal;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@font-face {
  font-style: normal;
  src: url("./fonts/NotoSansGeorgianRegular.ttf");
  font-family: "Noto Sans Georgian";
  font-weight: 400;
}
@font-face {
  font-style: bold;
  src: url("./fonts/NotoSansGeorgianSemiBold.ttf");
  font-family: "Noto Sans Georgian";
  font-weight: 600;
}
@font-face {
  font-style: normal;
  src: url("./fonts/ABeeZeeRegular.ttf");
  font-family: "ABeeZee";
  font-weight: 400;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(222, 222, 253);
  border-radius: 100vh;
  border: 1px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(235, 235, 252);
}

input:focus {
  outline: none !important;
}

.popup_container {
  height: 100%;
  width: 100vw !important;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
}

.popup {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
}

.popup_container2 {
  height: 80%;
  width: 100vw !important;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
}

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.greece-bg {
  background-image: url(../img//greeceimg.jpg);
}

.wolt-bg {
  background-image: url(../img//wolt.png);
}

.nChina-bg {
  background-image: url(../img//nChina.jpeg);
}
